import React, { useContext, useState, useEffect } from 'react';
import { AppContext } from '~/providers/AppProvider';
import styled from 'styled-components';
import { useRouter } from 'next/router';

import dynamic from 'next/dynamic';
const Menu = dynamic(async () => await import('~/components/menu'));

const MobileMenu = dynamic(async () => await import('~/components/mobileMenu'));

const TopBar = () => {
  const router = useRouter();
  const appCtx = useContext(AppContext);
  const [barWithBg, setBarWithBg] = useState(false);

  const [isHomepage, setIsHomepage] = useState(router.pathname === '/[lang]' || router.pathname === '/');

  useEffect(() => {
    setIsHomepage(router.pathname === '/[lang]' || router.pathname === '/');
  }, [router.pathname]);

  if (isHomepage) {
    const onScroll = (e) => {
      setBarWithBg(e.target.documentElement.scrollTop > document.getElementById('video_bg')?.offsetHeight);
    };

    useEffect(() => {
      window.addEventListener('scroll', onScroll);
      return () => {
        window.removeEventListener('scroll', onScroll);
      };
    }, []);
  }

  return (
    <>
      {barWithBg ? (
        <NavWrapper haveBg={barWithBg} className={isHomepage ? 'isHP' : ''}>
          <Wrapper>
            <Menu isHomepage={isHomepage} />
            <MobileMenu isHomepage={isHomepage} />
          </Wrapper>
        </NavWrapper>
      ) : (
        <NavWrapper className={isHomepage ? 'isHP' : ''}>
          <Menu isHomepage={isHomepage} />
          <MobileMenu isHomepage={isHomepage} />
        </NavWrapper>
      )}
    </>
  );
};

const Wrapper = styled.div`
  max-width: calc(1400px - 2em);
  display: flex;
  justify-content: space-around;
  align-items: center;

  width: 100%;
  margin: 0 auto;
`;
const NavWrapper = styled.div`
  z-index: 999;
  position: fixed;
  width: 100%;
  padding: 0 1em;
  height: 60px;
  display: flex;
  background: #0f2a5e;
  left: 0;
  top: 0;
  transition: 0.3s all;
  justify-content: space-around;

  &.isHP {
    background-color: ${(props) => (props.haveBg ? '#0f2a5e' : 'transparent')};

    margin-left: auto;
    margin-right: auto;
    right: 0;
    max-width: ${(props) => (props.haveBg ? 'unset' : '1400px')};
  }
`;

export default TopBar;
