import React, { useContext } from 'react';
import { AppContext } from '~/providers/AppProvider';
import styled from 'styled-components';
import { useRouter } from 'next/router';
import TopBar from '~/components/topBar';
import dynamic from 'next/dynamic';

const Footer = dynamic(async () => await import('~/components/footer'));

const Layout = ({ children, extraPadding = false }) => {
  const router = useRouter();
  const appCtx = useContext(AppContext);

  return (
    <>
      <TopBar />
      <ChildrenContent>{children}</ChildrenContent>
      <Footer />
      {extraPadding && <BottomPadding />}
    </>
  );
};

const BottomPadding = styled.div`
  padding-bottom: 91px;
  @media screen and (max-width: 700px) {
    padding-bottom: 150px;
  }
`;

export default Layout;

const ChildrenContent = styled.div`
  margin-top: 60px;
`;
